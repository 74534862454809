import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from './style.css';

export default class ErrorMessage extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        showButton: PropTypes.bool,
    };

    static defaultProps = {
        showButton: true,
    };

    render() {
        return (
            <div className={style.layout}>
                <div className={style.body}>
                    <div className={style.logo} />
                    <div className={style.title1}>Boys & Girls Clubs</div>
                    <div className={style.title2}>of America</div>
                    {this.props.children}

                    {this.props.showButton && (
                        <div className={style.homeLink}>
                            <Link to="/" className="btn btn-primary">
                                Go back to home
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
