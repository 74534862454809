import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getHashForClubCode } from 'js/utils.js';
import ErrorMessage from 'js/components/ErrorMessage';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';
import { useDispatch } from 'react-redux';
import { setClubInfo } from 'js/reducers/bgClub.js';

const Boilerplate = window.cccisd.boilerplate;

const Component = props => {
    const { history } = props;
    const { clubAccessCode, hash } = props.match.params;

    const isClubAccessCodeCorrect = /^\d{6}$/.test(clubAccessCode) && hash === getHashForClubCode(clubAccessCode);

    const dispatch = useDispatch();
    const [showWrongCodeError, setShowWrongCodeError] = useState(false);
    useEffect(() => {
        if (!isClubAccessCodeCorrect) {
            return;
        }

        const checkClubCode = async () => {
            const result = await axios.get(Boilerplate.route('get.club.info', { accessCodeId: clubAccessCode }));

            if (result.data.status !== 'success' || result.data.club.participatingThisYear !== 1) {
                setShowWrongCodeError(true);
            } else {
                const clubInfo = result.data.club;
                dispatch(setClubInfo(clubInfo));
                history.push('/member');
            }
        };

        checkClubCode();
    }, []);

    if (!isClubAccessCodeCorrect) {
        return (
            <ErrorMessage showButton={false}>
                <div>The link is broken</div>
            </ErrorMessage>
        );
    }

    if (showWrongCodeError) {
        return (
            <ErrorMessage showButton={false}>
                <div>The club access code is wrong or club is not participating this year</div>
            </ErrorMessage>
        );
    }

    return <Loader loading />;
};

Component.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};

export default Component;
